<script setup lang="ts">
import { IVideo } from '@/interface/IImage';
import { PropType } from 'vue';
import { onMounted, ref, watch } from 'vue'
import ImgComponent from './ImgComponent.vue';
const config = useRuntimeConfig()

const props = defineProps({
  srcVideo: {
    type: Object as PropType<IVideo>,
    required: false,
  },
  active: {
    type: Boolean,
    default: true,
  },
})
const videoAutoplay = ref<HTMLVideoElement | null>(null)
onMounted(() => {
  if (videoAutoplay.value) {
    videoAutoplay.value.autoplay = true
    videoAutoplay.value.play()
  }
})

watch(
  () => props.active,
  () => {
    if (props.active) if (videoAutoplay.value) videoAutoplay.value.play()
  }
)
</script>

<template>
  <div>
    <div class="video video__clip shade-frame-blue">
      <ImgComponent
        src="/uploads/header_first_frame_cc28e6cfde.webp?updated_at=2024-06-05T13:20:18.172Z"
        alt="header"
        format="webp"
        class="absolute -z-10 video__source"
      />
      <video
        v-if="srcVideo"
        ref="videoAutoplay"
        loop
        muted
        playsinline
        class="video__source z-10"
        loading="lazy"
      >
        <source :src="`${config.public.baseUrl}${srcVideo.large}`" />
        <track kind="subtitles" />
        <meta name="description" content="Home page movie" />
      </video>
    </div>
    <svg class="svg__clip">
      <clipPath id="micro__polygon">
        <rect width="100%" height="70%" />
        <ellipse cx="50%" cy="55%" rx="70%" ry="30%" />
      </clipPath>
      <clipPath id="polygon">
        <rect width="100%" height="70%" />
        <ellipse cx="50%" cy="65%" rx="70%" ry="30%" />
      </clipPath>
      <clipPath id="macro--polygon">
        <rect width="100%" height="70%" />
        <ellipse cx="50%" cy="20%" rx="70%" ry="80%" />
      </clipPath>
      <clipPath id="vertical--polygon">
        <rect width="100%" height="100%" />
      </clipPath>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.shade-frame-blue {
  position: absolute;
  width: 100vw;
  height: 0;

  &::after {
    content: '';
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--color-shade-header);
  }
}

.svg__clip {
  width: 100vw;
  height: 125vh;
}

.video {
  width: 100vw;
  height: 125vh;

  &__clip {
    -webkit-clip-path: url(#micro--polygon);
    clip-path: url(#micro--polygon);
  }

  &__source {
    height: 145vh;
    width: 100vw;
    object-fit: cover;
    object-position: bottom;
  }
}

@media screen and (min-width: 700px) {
  .video {
    height: 125vh;

    &__clip {
      -webkit-clip-path: url(#polygon);
      clip-path: url(#polygon);
    }
  }
}

@media screen and (min-width: 1100px) {
  .video__clip {
    -webkit-clip-path: url(#macro--polygon);
    clip-path: url(#macro--polygon);
  }
}

@media (max-height: 400px) {
  .video {
    &__source {
      height: 150vh;
      min-height: 420px;
    }

    &__clip {
      min-height: 1000px;
      -webkit-clip-path: url(#vertical--polygon);
      clip-path: url(#vertical--polygon);
    }
  }
}
</style>
